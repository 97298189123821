import { configureStore } from '@reduxjs/toolkit';
import analyticsSlice from './reducers/analyticsSlice';
import userSlice from './reducers/user';
import quizSlice from './reducers/quiz';

const localStorageMiddleware = (store: any) => (next: any) => (action: any) => {
    localStorage.setItem(
        'answers',
        JSON.stringify(store.getState().quiz.answers)
    );
    return next(action);
};

export const store = configureStore({
    reducer: {
        analytics: analyticsSlice,
        user: userSlice,
        quiz: quizSlice,
    },
});
