import { initializeApp } from 'firebase/app';
import { getRemoteConfig } from 'firebase/remote-config';

const firebaseConfig = {
    apiKey: 'AIzaSyDuhJlPpGMwu_EqtPuzjQHPA9ox3cXHxGU',
    authDomain: 'fishbox-landing.firebaseapp.com',
    projectId: 'fishbox-landing',
    storageBucket: 'fishbox-landing.appspot.com',
    messagingSenderId: '90805187719',
    appId: '1:90805187719:web:081712e31d50399383f422',
    measurementId: 'G-NK5C54QQP4',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const remoteConfig = getRemoteConfig(app);

remoteConfig.settings.minimumFetchIntervalMillis = 0;
