import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    email: '',
    country: 'default',
};

const configSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserEmail(state, action) {
            state.email = action.payload;
        },
        setCountry(state, action) {
            state.country = action.payload;
        },
    },
});

export default configSlice.reducer;
export const { setUserEmail, setCountry } = configSlice.actions;
