import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/fonts/fonts.css';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const initialPaypalOptions: any = {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: 'USD',
    intent: 'capture',
    vault: true,
};

root.render(
    <Provider store={store}>
        <PayPalScriptProvider options={initialPaypalOptions}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </PayPalScriptProvider>
    </Provider>
);
