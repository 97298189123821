export const ROUTES = {
    HOME: '/',
    PAYWALL: '/paywall',
    QUIZ: '/quiz',
    QUIZ_TEST: '/quiz-geo',
    QUIZ_LURES: '/quiz-lures',
    FINISH: '/finish',
    LANDING: '/landing',
    EXTERNAL_TERMS: 'https://fishboxapp.com/terms',
    EXTERNAL_PRIVACY: 'https://fishboxapp.com/privacy',
};
