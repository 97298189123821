import { createSlice } from '@reduxjs/toolkit';
import { QUIZ_PAGE_QUERY_PARAMETER } from '../../constants/data';

let params = new URLSearchParams(window.location.search);
let currentPage = params.get(QUIZ_PAGE_QUERY_PARAMETER);

let localStorageAnswers = localStorage.getItem('answers');
let localStorageQuiz = localStorage.getItem('defaultQuizStore');

const initialState = localStorageQuiz
    ? JSON.parse(localStorageQuiz)
    : {
          currentStep: 1,
          activeItems: [],
          answers: localStorageAnswers ? JSON.parse(localStorageAnswers) : {},
          cityData: {},
          cityName: '',
          st: '',
          trialCost: null,
          isPopupOpen: false,
          loading: false,
          userEmail: '',
          planName: '',
          quizType: '',
      };

const configSlice = createSlice({
    name: 'quiz',
    initialState,
    reducers: {
        setCurrentStep(state, action) {
            state.currentStep = action.payload;
        },
        setActiveItems(state, action) {
            state.activeItems = action.payload;
        },
        setAnswers(state, action) {
            state.answers = { ...state.answers, ...action.payload };
        },
        setPlan(state, action) {
            state.answers = { ...state.answers, ...action.payload };
        },
        setCityName(state, action) {
            state.cityName = action.payload;
        },
        setCityData(state, action) {
            state.cityData = action.payload;
        },
        setSt(state, action) {
            state.st = action.payload;
        },
        setTrialCost(state, action) {
            state.trialCost = action.payload;
        },
        setPopupOpen(state, action) {
            state.isPopupOpen = action.payload;
        },
        setUserEmail(state, action) {
            state.userEmail = action.payload;
        },
        setPlanName(state, action) {
            state.planName = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setQuizType(state, action) {
            state.quizType = action.payload;
        },
    },
});

export default configSlice.reducer;
export const {
    setCurrentStep,
    setActiveItems,
    setAnswers,
    setCityData,
    setCityName,
    setSt,
    setTrialCost,
    setPopupOpen,
    setUserEmail,
    setPlanName,
    setLoading,
    setQuizType,
} = configSlice.actions;
