import amplitude from 'amplitude-js';
import Cookies from 'js-cookie';
import { isIOS, isAndroid, isDesktop } from 'react-device-detect';

const currentDevice = () => {
    if (isIOS) {
        return 'IOS';
    }
    if (isAndroid) {
        return 'Android';
    }
    if (isDesktop) {
        return 'Desktop';
    }
    return 'unknown';
};

export const amplitudeInit = () => {
    let userId = Cookies.get('avilunaid');
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY, userId, {
        includeReferrer: true,
        includeUtm: true,
        includeGclid: true,
        includeFbclid: true,
        logAttributionCapturedEvent: true,
    });
};

export const setUserProperties = (experiments) => {
    const identify = new amplitude.Identify()
        .set('fbp', Cookies.get('_fbp') || 'none')
        .set('fbc', Cookies.get('_fbc') || 'none')
        .set('ttclid', Cookies.get('ttclid') || 'none')
        .set('gclid', Cookies.get('gclid') || 'none')
        .set('platform', currentDevice())
        .set('experiments', experiments)
        .set(
            'href',
            (() => {
                const currentUrl = new URL(window.location);
                if (currentUrl.href && currentUrl.href.length > 1) {
                    return currentUrl.href;
                }
                return null;
            })() || 'none'
        );
    return amplitude.getInstance().identify(identify);
};

export const setUserPropertiesFromObj = (obj) => {
    const identify = new amplitude.Identify();
    Object.keys(obj).forEach((key) => {
        identify.set(key, obj[key]);
    });
    return amplitude.getInstance().identify(identify);
};

export const onboardingEvent = () => {
    return amplitude.getInstance().logEvent('Onboarding', {
        value: 'web_payments_2',
        price: localStorage.getItem('price'),
    });
};

export const firstOpenAction = () => {
    return amplitude
        .getInstance()
        .logEvent('First_Open_action', { landing: 'web_payments_2' });
};

export const landingScrollEvent = (data, headerClick, result) => {
    if (headerClick) {
        return amplitude
            .getInstance()
            .logEvent('Landing_scroll', { source: data, result: result });
    } else {
        return amplitude
            .getInstance()
            .logEvent('Landing_scroll', { result: data });
    }
};

export const paymentScrollEvent = (data, handleClick, result) => {
    if (handleClick) {
        return amplitude
            .getInstance()
            .logEvent('Payment_scroll', { source: data, result: result });
    } else {
        return amplitude
            .getInstance()
            .logEvent('Payment_scroll', { result: data });
    }
};

export const paymentTransactionInitiated = (type) => {
    return amplitude
        .getInstance()
        .logEvent('payment transaction initiated', { type: type });
};

export const paymentTransactionCompleted = (screen, eventObj) => {
    return amplitude.getInstance().logEvent('payment transaction completed', {
        screen: screen,
        ...eventObj,
    });
};

export const paymentTransactionFailed = (message) => {
    return amplitude
        .getInstance()
        .logEvent('payment transaction failed', { message });
};

export const paymentScreenAppear = (data) => {
    window.dataLayer.push({ event: 'payment screen appear', ...data });
    return amplitude.getInstance().logEvent('payment screen appear', data);
};

export const paymentScreenInitiated = (data) => {
    return amplitude.getInstance().logEvent('payment screen initiated', data);
};

export const paymentScreenDisappear = (data) => {
    return amplitude.getInstance().logEvent('payment screen disappear', data);
};

export const purchasePostBack = (dataObj) => {
    window.dataLayer.push({ event: 'purchase postback', ...dataObj });
    return amplitude.getInstance().logEvent('purchase postback', dataObj);
};

export const congratulationsScreenAppear = () => {
    return amplitude.getInstance().logEvent('congratulation screen appear');
};

export const installTapped = (type) => {
    return amplitude.getInstance().logEvent('install tapped', { type: type });
};

export const userGetStarted = () => {
    return amplitude.getInstance().logEvent('User_Get_started');
};

export const stepPersonalizationView = () => {
    return amplitude.getInstance().logEvent('Step_Personalization_view');
};

export const stepPersonalizationComplete = () => {
    return amplitude.getInstance().logEvent('Step_Personalization_complete');
};

export const setVersion = () => {
    return amplitude
        .getInstance()
        .setVersionName(process.env.REACT_APP_VERSION);
};

// quiz events
export const quizFirstOpenEvent = (type) => {
    return amplitude.getInstance().logEvent('quiz_first_open_action', {
        type: type,
    });
};

export const quizContinueTapped = (key, value, dataObj) => {
    let data = {
        [key]: value,
        page_name: key,
    };
    if (dataObj) {
        data = dataObj;
    }
    window.dataLayer.push({ event: 'quiz_continue_tapped', ...data });
    return amplitude.getInstance().logEvent('quiz_continue_tapped', data);
};

export const quizBackTapped = (value) => {
    return amplitude.getInstance().logEvent('quiz_back_tapped', {
        result: value,
    });
};

export const quizScrollTapped = (value) => {
    return amplitude.getInstance().logEvent('quiz_skroll_tapped ', {
        result: value,
    });
};

export const quizPlanSelectedTapped = (value) => {
    return amplitude.getInstance().logEvent('quiz_plan_selected ', {
        result: value,
    });
};

export const getAmplitudeUserId = () => {
    return amplitude.getInstance().getDeviceId();
};

export const paymentScreenClosed = (data) => {
    return amplitude.getInstance().logEvent('payment screen closed', data);
};

export const specialOfferAcceptedEvent = (data) => {
    return amplitude.getInstance().logEvent('special offer accepted', data);
};

export const specialOfferClosedEvent = (data) => {
    return amplitude.getInstance().logEvent('special_offer_closed', data);
};

export const setUserCountryEvent = (data) => {
    return amplitude.getInstance().logEvent('user_country', data);
};

// CHECK SCREEN EVENTS
export const checkPopupOpenEvent = () => {
    window.dataLayer.push({
        event: 'pop-up subscription screen did appear',
    });
};

export const goToAppEvent = (data) => {
    window.dataLayer.push({ event: 'Go_to_app_tapped', ...data });
};

export const buyAgainEvent = (data) => {
    window.dataLayer.push({ event: 'Buy_again', ...data });
};

export const checkPopupCloseEvent = (data) => {
    window.dataLayer.push({
        event: 'pop-up subscription screen did disappear',
        ...data,
    });
};
