import {
    fetchAndActivate,
    getAll,
    getString,
    getRemoteConfig,
} from 'firebase/remote-config';
import { remoteConfig } from '../firebase';

export const PRICE_KEY = 'Price';
export const BACK_TYPE_KEY = 'back_type';
export const BACK_TYPE_BUTTON = 'button';
export const BACK_TYPE_ARROW = 'arrow';
export const SINGLE_CHOICE_BEHAVIOR_KEY = 'single_choice_behavior';
export const SINGLE_CHOICE_BEHAVIOR_CONTINUE_CLICK = 'continue_click';
export const SINGLE_CHOICE_BEHAVIOR_OPTION_CLICK = 'option_click';
export const PRE_PAYWALL_VIEW_KEY = 'pre_paywall_view';
export const PRE_PAYWALL_VIEW_KEY_CHOOSE_TRIAL = 'choose_trial_price';
export const PRE_PAYWALL_VIEW_KEY_TRIAL_REMOVED = 'trial_price_removed';
export const FORMDESIGNER_LINKS = 'formdesigner_links';
export const PLANS_KEY = 'plans';
export const EXPERIMENTS = 'experiments';
export const QUIZ_FIRST_STEP = 'quiz_first_step';
export const QUIZ_FIRST_STEP_TYPE_OF_WATER = 'type_of_water';
export const QUIZ_FIRST_STEP_TYPE_OF_WATER_WITH_MAP = 'type_of_water_with_map';
export const QUIZ_FIRST_STEP_LOADING = 'loading';
export const SALTWATER_QUESTION_VIEW = 'saltwater_questions_view';
export const NEW_PAYWALL_VIEW = 'new_paywall_view';

export const MAGIC_SCREEN_VIEW = 'magic_screen_view';
export const MAGIC_SCREEN_VIEW_3_STEPS = '3_steps_analytics';
export const MAGIC_SCREEN_VIEW_PERSONAL_MAP = 'personal_map_search';
export const MAP_ZOOM = 'map_zoom';
export const MAP_STYLE = 'map_style';
export const MAP_STYLE_DEFAULT = 'mapbox://styles/mapbox/satellite-streets-v12';
export const POPUP_PAYWALL_VIEW_KEY = 'pop_up_paywall_view';
export const POPUP_PAYWALL_VIEW_TEST = 'test';
export const POPUP_PAYWALL_VIEW_TEST_2 = 'test2';
export const POPUP_PAYWALL_VIEW_CONTROL = 'control';
export const HIDDEN_STATES_SCREEN_KEY = 'hidden_states_screen';
export const NEW_AU_PRICE = 'new_au_price';
export const TWEAKS_V1 = 'tweaks_v1';
export const TWEAKS_V1_LIMIT_KEY = 'limit';
export const LIVE_MAP_V2 = 'live_map_v2';

export const THREE_PLANS_ON = 'three_plans_on';

remoteConfig.defaultConfig = {
    [PLANS_KEY]: {
        default: {
            headerText:
                'Your trial plan has been reserved for the next 15 minutes.',
            hiwText: 'How your trial works',
            trialCost: '0.99',
            planCost: '27.99',
            months: '3',
            monthsCost: '9.33',
            discountPercent: '54',
            oldPrice: '$59.99',
            startButtonText: 'Start My 7-Day Trial',
            paymentButtonText:
                'You will only be charged $0.99 for your 7-day trial',
            willBeText: '$27.99 every quarter',
            firstItemText: '7-day trial for $0.99',
            priceID: 'price_1Nm2s1DLwYtEf8BNBRUXEUn4',
            applePayTitle: 'for 7-Day Trial',
            currency: 'usd',
            currency_symbol: '$',
            country: 'US',
        },
        test_60: {
            headerText:
                'Your trial plan has been reserved for the next 15 minutes. Test 60 with $3 trial',
            hiwText: 'How your trial works',
            trialCost: '3.00',
            planCost: '60.00',
            months: '6',
            monthsCost: '10.00',
            discountPercent: '50',
            oldPrice: '120.00',
            startButtonText: 'Start My 7-Day Trial',
            paymentButtonText:
                'You will only be charged $0.99 for your 7-day trial',
            willBeText: '$60.00 every 6 months',
            firstItemText: '7-day trial for $3.00',
            priceID: 'price_1NO1tcDLwYtEf8BNs6XXlnAG',
            applePayTitle: 'Fishbox subscription 60',
            currency: 'usd',
            currency_symbol: '$',
            country: 'US',
        },
        live: {
            headerText:
                'Your trial plan has been reserved for the next 15 minutes.',
            hiwText: 'How your trial works',
            trialCost: '0.99',
            planCost: '27.99',
            months: '12',
            monthsCost: '2.29',
            discountPercent: '54',
            oldPrice: '59.99',
            startButtonText: 'Start My 7-Day Trial',
            paymentButtonText:
                'You will only be charged $0.99 for your 7-day trial',
            willBeText: '$27.99 every 12 months',
            firstItemText: '7-day trial for $0.99',
            priceID: 'price_1MWdNWDLwYtEf8BNVLpvoR9s',
            applePayTitle: 'Fishbox subscription 27.99',
            currency: 'usd',
            currency_symbol: '$',
            country: 'US',
        },
        live_AU: {
            headerText:
                'Your trial plan has been reserved for the next 15 minutes.',
            hiwText: 'How your trial works',
            trialCost: '0.99',
            planCost: '44.99',
            months: '12',
            monthsCost: '3.75',
            discountPercent: '52',
            oldPrice: '94.99',
            startButtonText: 'Start My 7-Day Trial',
            paymentButtonText:
                'You will only be charged $0.99 for your 7-day trial',
            willBeText: '$44.99 every 12 months',
            firstItemText: '7-day trial for $0.99',
            priceID: 'price_1O3D8aDLwYtEf8BNy7XhQZtb',
            applePayTitle: 'Fishbox subscription 44.99',
            currency: 'aud',
            currency_symbol: '$',
            country: 'AU',
        },
        test_24: {
            headerText:
                'Your trial plan has been reserved for the next 15 minutes. Test 24 with $0 trial',
            hiwText: 'How your trial works',
            trialCost: '0',
            planCost: '24.00',
            months: '12',
            monthsCost: '4.00',
            discountPercent: '50',
            oldPrice: '48.00',
            startButtonText: 'Start My 7-Day Trial',
            paymentButtonText:
                'You will only be charged $0.99 for your 7-day trial',
            willBeText: '$48.00 every year',
            firstItemText: '7-day trial free',
            priceID: 'price_1NO2KiDLwYtEf8BNSeI1bxfE',
            applePayTitle: 'Fishbox subscription 24',
            currency: 'usd',
            currency_symbol: '$',
            country: 'US',
        },
        'AU_39.99_test': {
            headerText:
                'Test 1: Your trial plan has been reserved for the next 15 minutes.',
            hiwText: 'Test 2: How your trial works',
            trialCost: '2.99',
            planCost: '60.99',
            months: '10',
            monthsCost: '111.33',
            discountPercent: '40',
            oldPrice: '111.99',
            startButtonText: 'Test 3: Start My 7-Day Trial',
            paymentButtonText:
                'Test 4: You will only be charged A$1.99 for your 7-day trial',
            willBeText: 'Test 5: A$39.99 every year',
            firstItemText: 'Test 6: 7-day trial for A$1.99',
            priceID: 'price_1NizIWDLwYtEf8BNyi8QBibg',
            applePayTitle: 'Test 7: Fishbox subscription 24',
            currency: 'aud',
            currency_symbol: '$',
            country: 'AU',
        },
        '11.99m': {
            headerText:
                'Your trial plan has been reserved for the next 15 minutes.',
            hiwText: 'How your trial works',
            trialCost: '0.99',
            planCost: '11.99',
            months: '1',
            monthsCost: '11.99',
            discountPercent: '54',
            oldPrice: '$21.99',
            startButtonText: 'Start My 7-Day Trial',
            paymentButtonText:
                'You will only be charged $0.99 for your 7-day trial',
            willBeText: '$11.99 every month',
            firstItemText: '7-day trial for $0.99',
            priceID: 'price_1Nm2rJDLwYtEf8BNJTw5tmav',
            applePayTitle: 'Fishbox subscription 11.99',
            currency: 'usd',
            currency_symbol: '$',
            country: 'US',
        },
        '9.99m': {
            headerText:
                'Your trial plan has been reserved for the next 15 minutes.',
            hiwText: 'How your trial works',
            trialCost: '0.99',
            planCost: '9.99',
            months: '1',
            monthsCost: '9.99',
            discountPercent: '54',
            oldPrice: '$20.99',
            startButtonText: 'Start My 7-Day Trial',
            paymentButtonText:
                'You will only be charged $0.99 for your 7-day trial',
            willBeText: '$9.99 every month',
            firstItemText: '7-day trial for $0.99',
            priceID: 'price_1Nm2qUDLwYtEf8BNNG1XRmU0',
            applePayTitle: 'Fishbox subscription 9.99',
            currency: 'usd',
            currency_symbol: '$',
            country: 'US',
        },
        '27.99q': {
            headerText:
                'Your trial plan has been reserved for the next 15 minutes.',
            hiwText: 'How your trial works',
            trialCost: '0.99',
            planCost: '27.99',
            months: '3',
            monthsCost: '9.33',
            discountPercent: '54',
            oldPrice: '$59.99',
            startButtonText: 'Start My 7-Day Trial',
            paymentButtonText:
                'You will only be charged $0.99 for your 7-day trial',
            willBeText: '$27.99 every quarter',
            firstItemText: '7-day trial for $0.99',
            priceID: 'price_1Nm2s1DLwYtEf8BNBRUXEUn4',
            applePayTitle: 'Fishbox subscription 27.99',
            currency: 'usd',
            currency_symbol: '$',
            country: 'US',
        },
        '27.99q_test': {
            headerText:
                'Your trial plan has been reserved for the next 15 minutes.',
            hiwText: 'How your trial works',
            trialCost: '7.99',
            planCost: '182.97',
            months: '3',
            monthsCost: '20.33',
            discountPercent: '54',
            oldPrice: '$59.99',
            startButtonText: 'Start My 7-Day Trial',
            paymentButtonText:
                'You will only be charged $1.99 for your 7-day trial',
            willBeText: '182.97 $ every month or year',
            firstItemText: '7-day trial for $1.99',
            priceID: 'price_1NxGFNDLwYtEf8BN5iR0jU5y',
            applePayTitle: 'Fishbox subscription 27.99',
            currency: 'usd',
            currency_symbol: '$',
            country: 'US',
            paypalProduct: 'P-55V00264W7887534CMUN6H3Q',
        },
        '27.99m': {
            headerText:
                'Your trial plan has been reserved for the next 15 minutes.',
            hiwText: 'How your trial works',
            trialCost: '0.99',
            planCost: '27.99',
            months: '1',
            monthsCost: '27.99',
            discountPercent: '54',
            oldPrice: '59.99',
            startButtonText: 'Start My 7-Day Trial',
            paymentButtonText:
                'You will only be charged $0.99 for your 7-day trial',
            willBeText: '$27.99 every month',
            firstItemText: '7-day trial for $0.99',
            priceID: 'price_1O6w2GDLwYtEf8BNUNwbWg7J',
            applePayTitle: 'Fishbox subscription 27.99',
            currency: 'usd',
            currency_symbol: '$',
            country: 'US',
        },
        'AU_39.99q': {
            headerText:
                'Your trial plan has been reserved for the next 15 minutes.',
            hiwText: 'How your trial works',
            trialCost: '0.99',
            planCost: '33.99',
            months: '3',
            monthsCost: '13.39',
            discountPercent: '52',
            oldPrice: '94.99',
            startButtonText: 'Start My 7-Day Trial',
            paymentButtonText:
                'You will only be charged $0.99 for your 7-day trial',
            willBeText: '$39.99 every 3 months',
            firstItemText: '7-day trial for $0.99',
            priceID: 'price_1OEsouDLwYtEf8BNOFklusON',
            applePayTitle: 'Fishbox Subscription with Trial',
            currency: 'aud',
            currency_symbol: '$',
            country: 'AU',
        },
        'AU_39.99m': {
            headerText:
                'Your trial plan has been reserved for the next 15 minutes.',
            hiwText: 'How your trial works',
            trialCost: '0.99',
            planCost: '39.99',
            months: '1',
            monthsCost: '39.99',
            discountPercent: '52',
            oldPrice: '94.99',
            startButtonText: 'Start My 7-Day Trial',
            paymentButtonText:
                'You will only be charged $0.99 for your 7-day trial',
            willBeText: '$39.99 every month',
            firstItemText: '7-day trial for $0.99',
            priceID: 'price_1OG3UxDLwYtEf8BNz2f5zTuZ',
            applePayTitle: 'Fishbox Subscription with Trial',
            currency: 'aud',
            currency_symbol: '$',
            country: 'AU',
        },
        'AU_39.99m_new_au_price': {
            headerText:
                'Your trial plan has been reserved for the next 15 minutes.',
            hiwText: 'How your trial works',
            trialCost: '0.99',
            planCost: '44.99',
            months: '1',
            monthsCost: '44.99',
            discountPercent: '52',
            oldPrice: '94.99',
            startButtonText: 'Start My 7-Day Trial',
            paymentButtonText:
                'You will only be charged $0.99 for your 7-day trial',
            willBeText: '$39.99 every month',
            firstItemText: '7-day trial for $0.99',
            priceID: 'price_1OG3UxDLwYtEf8BNz2f5zTuZ',
            applePayTitle: 'Fishbox Subscription with Trial',
            currency: 'aud',
            currency_symbol: '$',
            country: 'AU',
        },
    },
    [BACK_TYPE_KEY]: BACK_TYPE_ARROW,
    [SINGLE_CHOICE_BEHAVIOR_KEY]: SINGLE_CHOICE_BEHAVIOR_OPTION_CLICK,
    [PRE_PAYWALL_VIEW_KEY]: PRE_PAYWALL_VIEW_KEY_TRIAL_REMOVED,
    [MAP_STYLE]: MAP_STYLE_DEFAULT,
    [MAP_ZOOM]: 9,
    [QUIZ_FIRST_STEP]: QUIZ_FIRST_STEP_TYPE_OF_WATER,
    [MAGIC_SCREEN_VIEW]: MAGIC_SCREEN_VIEW_3_STEPS,
    [FORMDESIGNER_LINKS]: 'https://fd2.fishboxapp.com/form/view/207391',
    [SALTWATER_QUESTION_VIEW]: 'true',
    [QUIZ_FIRST_STEP]: QUIZ_FIRST_STEP_LOADING,
    [NEW_PAYWALL_VIEW]: 'true',
    [PRE_PAYWALL_VIEW_KEY]: PRE_PAYWALL_VIEW_KEY_CHOOSE_TRIAL,
    [POPUP_PAYWALL_VIEW_KEY]: POPUP_PAYWALL_VIEW_TEST,
    [HIDDEN_STATES_SCREEN_KEY]: 'false',
    [NEW_AU_PRICE]: 'false',
    [TWEAKS_V1]: 'false',
    [LIVE_MAP_V2]: 'false',
    [THREE_PLANS_ON]: 'false',
};

export const remoteFunction = (
    dispatch,
    setPlanFunction,
    planKey,
    setRemoteKeysValues
) => {
    let price;
    let plans;
    let newAuPrice;
    let experiments;
    fetchAndActivate(remoteConfig)
        .then(() => {
            return getAll(remoteConfig);
        })
        .then((data) => {
            price = getString(remoteConfig, PRICE_KEY);
            plans = getString(remoteConfig, PLANS_KEY);
            newAuPrice = getString(remoteConfig, NEW_AU_PRICE);
            experiments = getString(remoteConfig, EXPERIMENTS);
            Object.keys(data).forEach((key) => {
                dispatch(
                    setRemoteKeysValues({
                        key,
                        value: getString(remoteConfig, key),
                    })
                );
            });
            //set experiments
            window.dataLayer.push({
                event: 'experiment',
                experiments: experiments,
            });
        })
        .catch((e) => {
            plans = JSON.stringify(remoteConfig.defaultConfig[PLANS_KEY]);
            Object.keys(remoteConfig.defaultConfig).forEach((key) => {
                dispatch(
                    setRemoteKeysValues({
                        key,
                        value: remoteConfig.defaultConfig[key],
                    })
                );
            });
        })
        .finally(() => {
            localStorage.setItem('price', price);
            if (Object.keys(JSON.parse(plans)).find((key) => planKey === key)) {
                // newAuPrice === 'true' &&
                // (planKey.includes('AU') || planKey.includes('au'))
                //     ? dispatch(
                //           setPlanFunction(
                //               JSON.parse(plans)[`${planKey}_new_au_price`]
                //           )
                //       )
                //     :
                dispatch(setPlanFunction(JSON.parse(plans)[planKey]));
            } else {
                dispatch(setPlanFunction(JSON.parse(plans)['default']));
            }
        });
};
