import React from 'react';
import loader from '../../assets/images/common/spiner.gif';

const Loader = () => {
    return (
        <div className="flex h-dvh items-center justify-center bg-[#ffffff]">
            <img className={'h-20 w-20'} src={loader} />
        </div>
    );
};

export default Loader;
