import increaseBullet from '../assets/images/increase/bullet.svg';
import preciseBullet1 from '../assets/images/precise/precise_1.png';
import preciseBullet2 from '../assets/images/precise/precise_2.png';
import preciseBullet3 from '../assets/images/precise/precise_3.png';
import preciseBullet4 from '../assets/images/precise/precise_4.png';
import noa from '../assets/images/increase/noaa.png';
import navionics from '../assets/images/increase/navionics.png';
import navionicsSecond from '../assets/images/premium/nova.png';
import slide1 from '../assets/images/slider/slide_1.png';
import comment1 from '../assets/images/comments/comment_1.png';
import comment2 from '../assets/images/comments/comment_2.png';
import comment3 from '../assets/images/comments/comment_3.png';
import comment4 from '../assets/images/comments/comment_4.png';
import comment5 from '../assets/images/comments/comment_5.png';

import comment21 from '../assets/images/comments/comment_21.png';
import comment22 from '../assets/images/comments/comment_22.png';
import comment23 from '../assets/images/comments/comment_23.png';

import fresh1 from '../assets/images/land/freshwater/fresh/fresh_1.png';
import fresh2 from '../assets/images/land/freshwater/fresh/fresh_2.png';
import fresh3 from '../assets/images/land/freshwater/fresh/fresh_3.png';
import salt1 from '../assets/images/land/freshwater/salt/salt_1.png';
import salt2 from '../assets/images/land/freshwater/salt/salt_2.png';
import salt3 from '../assets/images/land/freshwater/salt/salt_3.png';

export const RADIUS_WATER_MARKERS = 200;
export const LIMIT_WATER_MARKERS = 50;
export const FRESH_WATER_IMAGES_ARRAY = [fresh1, fresh2, fresh3];

export const SALT_WATER_IMAGES_ARRAY = [salt1, salt2, salt3];

export const COUNTRY_LIST = [
    {
        name: 'USA',
        coordinates: { lat: 37.0902, long: -95.7129 },
        link: 'https://fd2.fishboxapp.com/form/view/207391',
    },
    {
        name: 'Canada',
        coordinates: { lat: 56.1304, long: -106.3468 },
        link: 'https://fd2.fishboxapp.com/form/view/214229',
    },
    {
        name: 'South Africa',
        coordinates: { lat: -30.5595, long: 22.9375 },
        link: 'https://fd2.fishboxapp.com/form/view/215936',
    },
    {
        name: 'United Kingdom',
        coordinates: { lat: 55.3781, long: -3.436 },
        link: 'https://fd2.fishboxapp.com/form/view/215882',
    },
];
export const SLIDER_DELAY = 2000;
export const SLIDER_DELAY_AFTER_HANDLE_CHANGED = 5000;
export const QUIZ_PAGE_QUERY_PARAMETER = 'current_page';

export const PLANS: any = {
    '27': {
        id: 0,
        trialPrice: 0.99,
        oldPrice: '$59.99',
        safePercent: 54,
        price: 27.99,
        pricePerMonth: '$2.29 / month',
        priceId: process.env.REACT_APP_PRODUCT,
        text: '$0.99',
    },
    '49': {
        id: 0,
        trialPrice: 0.99,
        oldPrice: '$89.99',
        safePercent: 54,
        price: 49.99,
        pricePerMonth: '$3.99 / month',
        priceId: process.env.REACT_APP_PRODUCT_49,
        text: '$0.99',
    },
    '74': {
        id: 0,
        trialPrice: 0.99,
        oldPrice: '$139.99',
        safePercent: 54,
        price: 74.99,
        pricePerMonth: '$5.99 / month',
        priceId: process.env.REACT_APP_PRODUCT_74,
        text: '$0.99',
    },
    '19': {
        id: 0,
        trialPrice: 0.99,
        oldPrice: '$39.99',
        safePercent: 54,
        price: 19.99,
        pricePerMonth: '$1.99 / month',
        priceId: process.env.REACT_APP_PRODUCT_19,
        text: '$0.99',
    },
};

export const TIMER_DELAY = 15;

export const product = {
    id: 2,
    text: 'Trial $0.99',
    bottomText: '$0.99',
    price: 27.99,
    trialPrice: 0.99,
    priceId: process.env.REACT_APP_PRODUCT,
};

export const increaseList = [
    {
        id: 0,
        icon: increaseBullet,
        text: `Explore our fishing map with more than 1.2 million public waterbodies all over<br class="lg:hidden"/> the world`,
    },
    {
        id: 1,
        icon: increaseBullet,
        text: 'Save your favorite spots and catches',
    },
    {
        id: 2,
        icon: increaseBullet,
        text: `<span>Use depth counter by NOAA</span>  <span><img style="width:18px;margin-left:2px" src=${noa} /></span>   `,
    },
    {
        id: 3,
        icon: increaseBullet,
        text: `<span><img style="width:87px; height:auto; position:absolute" src=${navionics} /></span>  <span style="text-indent:90px">  depth charts will be available soon</span> `,
    },
];

export const bottomList = [
    {
        id: 0,
        icon: increaseBullet,
        text: '7-day trial for $0.99',
    },
    {
        id: 1,
        icon: increaseBullet,
        text: 'No commitment - cancel any time',
    },
    {
        id: 2,
        icon: increaseBullet,
        text: `You will be sent an email receipt each time your subscription renews`,
    },
];

export const smartList = [
    {
        id: 0,
        icon: increaseBullet,
        text: 'Find out positive and negative factors that affect fishing',
    },
    {
        id: 1,
        icon: increaseBullet,
        text: 'Developed in collaboration with ichthyologists and professional anglers with 20+ years of experience',
    },
    {
        id: 2,
        icon: increaseBullet,
        text: 'Our unique functionality uses the most accurate sources of weather data',
    },
    {
        id: 3,
        icon: increaseBullet,
        text: 'Available for any body of water!',
    },
];

export const offerList = [
    {
        id: 0,
        icon: increaseBullet,
        text: `Reduce the time spent on finding <br/> the best place`,
    },
    {
        id: 1,
        icon: increaseBullet,
        text: `Understand the reason why <br/> the fish won\'t bite`,
    },
    {
        id: 2,
        icon: increaseBullet,
        text: 'Increase the chance of catching',
    },
];

export const preciseList = [
    {
        id: 0,
        icon: preciseBullet1,
        text: '<b>Hourly Weather</b> (wind strength and direction, atmospheric pressure, precipitation, etc)',
    },
    {
        id: 1,
        icon: preciseBullet2,
        text: '<b>Tides</b> (low & high)',
    },
    {
        id: 2,
        icon: preciseBullet3,
        text: '<b>Waves</b> (period, direction and temperature)',
    },
    {
        id: 3,
        icon: preciseBullet4,
        text: '<b>Solunar</b>',
    },
];

export const infoList = [
    {
        id: 0,
        icon: increaseBullet,
        text: 'Insights and fishing tips',
    },
    {
        id: 1,
        icon: increaseBullet,
        text: 'Video guide on how to tie knots. Learn more than 50 knots for any situations',
    },
    {
        id: 2,
        icon: increaseBullet,
        text: 'Users guides and FAQs',
    },
];

export const slides = [
    {
        id: 0,
        slide: slide1,
    },
];

export const premiumList = [
    {
        id: 0,
        icon: increaseBullet,
        text: 'Fishing Hot Spots Near You',
    },
    {
        id: 1,
        icon: increaseBullet,
        text: `<span>Depth Counters by NOAA</span>  <span><img style="width:18px;margin-left:2px" src=${noa} /></span>`,
    },
    {
        id: 7,
        icon: increaseBullet,
        text: `<span> Navionics </span> <span><img style="width:18px;margin-left:4px;margin-top:2px;margin-right:4px" src=${navionicsSecond}  /></span>`,
    },
    {
        id: 2,
        icon: increaseBullet,
        text: `Smart Fishing Forecast™`,
    },
    {
        id: 3,
        icon: increaseBullet,
        text: `Personal Notifications™`,
    },
    {
        id: 4,
        icon: increaseBullet,
        text: `Detailed Weather Forecast`,
    },
    {
        id: 5,
        icon: increaseBullet,
        text: `Insights and fishing tips`,
    },
    {
        id: 6,
        icon: increaseBullet,
        text: `Video guide on how to tie knots`,
    },
    {
        id: 8,
        icon: increaseBullet,
        text: `<span style="background:#00C65B; font-size:12px; color:white; border-radius:4px; margin-right:5px; padding:2px 9px 2px 9px">FREE BONUS</span>  <span>Personal Assistant™</span>`,
    },
];

export const commentsList = [
    {
        id: 0,
        icon: comment1,
        text: '“Fishing forecast works very well, the hour that was set for minor feeding brought a ton of bites. We caught lots of fish and my kids were super happy! Definitely recommend it 👍”',
    },
    {
        id: 1,
        icon: comment2,
        text: '“I love this app. It shows all you need before going out to fish. For beginners it shows how to tie knots and more. Keep up the great work.”',
    },
    {
        id: 2,
        icon: comment3,
        text: '“Im glad I found this app its definitely worth it better than all the other fishing apps.”',
    },
    {
        id: 3,
        icon: comment4,
        text: "“This app is well thought out. I can't wait to get on the water. Great research!”",
    },
    {
        id: 4,
        icon: comment5,
        text: '“This has the perfect amount of content for an app. All the knots alone are so nice to reference. Learned new spots around me too!”',
    },
];

export const commentsList2 = [
    {
        id: 0,
        icon: comment21,
        text: 'Caught the most fish I ever have with my son by timing our trip to align with the excellent fishing days from Fishbox!',
    },
    {
        id: 1,
        icon: comment22,
        text: 'As a musky fanatic I track the moon religiously. This app is accurate and the map feature is a great way to mark follows , catches, etc.',
    },
    {
        id: 2,
        icon: comment23,
        text: 'Great app! I found a lot of useful information, along with a step by step fishing knots video guide. It really can help you to become a pro in knot tying. You may even use the knowledge you got there to create your own knots. Thank you guys!',
    },
];

export const questionsList = [
    {
        id: 1,
        title: 'I’m a newbie/amateur, will this work for me?',
        text: 'YES! Fishbox has proven to work for anglers of all skill levels, and our newbies actually see some of the quickest results.',
    },
    {
        id: 2,
        title: 'I’m not very good with a phone. Do i need a degree in phones for this tools to work?',
        text: 'The app is very easy to access and operate from any device that can connect to the internet.',
    },
    {
        id: 3,
        title: 'This is a crazy low price for all of this value... what’s the catch?',
        text: "The catch is that you have to put in the work! And you will!!! Because you're ready for this tool and it's ready for you.",
    },
    {
        id: 4,
        title: 'Can I cancel subscription anytime?',
        text: 'You can cancel subscription any time in your App Store or Google Play subscription center.',
    },
    {
        id: 5,
        title: 'How quickly can I access the tools and start seeing tips and forecast?',
        text: "Immediately. Right after you've entered your data, you will be redirected to App Store or Google Play in one click and install the App.",
    },
];

export const howList = [
    {
        id: 0,
        title: 'Got your personalized map',
        throught: true,
        titleColor: 'greenButton',
        text: 'You successfully started your journey to smart fishing with Fishbox.',
    },
    {
        id: 1,
        title: 'Today',
        text: 'Your trial begins. Enjoy full access to all the premium content.',
    },
    {
        id: 2,
        title: '5 Day',
        text: 'Decide on keeping a Premium version of the Fishbox App.',
    },
    {
        id: 3,
        title: '7 Day',
        text: 'Your trial will be converted to a full price unless it’s canceled.',
    },
];
